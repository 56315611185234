import React from "react";
import './carousel.css';

import banner1 from '../../Images/image1.jpg';
import banner2 from '../../Images/Academics/11.jpg';
import banner3 from '../../Images/image3.jpg';

const Carousel = () =>{

    return(

        <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active" data-bs-interval="4000">
                    <img src={banner1} class="d-block w-100" alt="..." />
                    <div className="overlay"></div>
                    <div class="carousel-caption">
                        <h5 class="animated bounceInRight" style={{"animation-delay": "1s"}}>Welcome to GRKIST</h5>
{/*                         <p class="animated bounceInLeft" style={{"animation-delay": "2s"}}>Some representative placeholder content for the first slide.</p> */}
                        <a href="/about-us/principle" class="animated bounceInRight" style={{"animation-delay": "3s"}}>Learn More</a>
                    </div>
                </div>
                <div class="carousel-item" data-bs-interval="4000">
                    <img src={banner2} class="d-block w-100" alt="..." />
                    <div className="overlay"></div>
                    <div class="carousel-caption">
                        <h5 class="animated bounceInRight" style={{"animation-delay": "1s"}}>Follow your Dreams</h5>
                        <p class="animated bounceInLeft" style={{"animation-delay": "2s"}}>Some representative placeholder content for the second slide.</p>
                        <a href="/courses/undergraduate-courses" class="animated bounceInRight" style={{"animation-delay": "3s"}}>Learn More</a>
                    </div>
                </div>
                <div class="carousel-item" data-bs-interval="4000">
                    <img src={banner3} class="d-block w-100" alt="..." />
                    <div className="overlay"></div>
                    <div class="carousel-caption">
                        <h5 class="animated bounceInRight" style={{"animation-delay": "1s"}}>Diversified Learning</h5>
                        <p class="animated bounceInLeft" style={{"animation-delay": "2s"}}>Some representative placeholder content for the third slide.</p>
                        <a href="/placement/tpo&placements" class="animated bounceInRight" style={{"animation-delay": "3s"}}>Learn More</a>

                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default Carousel;
